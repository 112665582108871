import React from 'react'

type AntiSocialPrincipleProps = {
  lang: string
}

const AntiSocialPrinciple = ({ lang }: AntiSocialPrincipleProps) => {
  if (lang === 'ja')
    return (
      <>
        <h2>反社会的勢力に対する基本方針</h2>
        <p>
          当社は、暴力、威力および詐欺的手法を駆使して経済的利益を追求する集団または個人である反社会的勢力による被害を防止するため、これら勢力と一切の関係を遮断し毅然として対応するため、次の基本方針を宣言します。
        </p>
        <ol>
          <li>
            <p>
              反社会的勢力に対しては、組織全体として毅然たる態度で対応を図るとともに、反社会的勢力に対応する役職員の安全を確保します。
            </p>
          </li>
          <li>
            <p>
              平素から、警察、暴力追放運動推進センターおよび弁護士等の外部専門機関と緊密な連携関係を構築します。
            </p>
          </li>
          <li>
            <p>
              反社会的勢力とは、取引関係を含めて、一切の関係を遮断します。また、反社会的勢力による不当要求は拒絶します。
            </p>
          </li>
          <li>
            <p>
              反社会的勢力による不当要求に対しては、民事と刑事の両面から法的対応を行います。
            </p>
          </li>
          <li>
            <p>反社会的勢力に対して、裏取引や資金提供は一切行いません。</p>
          </li>
        </ol>
      </>
    )
  else
    return (
      // English version
      <>
        <h2>Anti-Social Forces Basic Principle</h2>
        <p>
          In order to prevent damage caused by antisocial forces, which are
          groups or individuals that pursue economic gain through the use of
          violence, force, and fraudulent methods, we declare the following
          basic policy to block any and all relationships with such forces and
          take a firm stand against them.
        </p>
        <ol>
          <li>
            <p>
              The entire organization shall take a resolute attitude toward
              antisocial forces and ensure the safety of officers and employees
              who deal with antisocial forces.
            </p>
          </li>
          <li>
            <p>
              We will establish close cooperative relationships with the police,
              the National Center for Removal of Criminal Organizations,
              lawyers, and other outside professional organizations.
            </p>
          </li>
          <li>
            <p>
              We shall not have any relationship, including business
              relationships, with antisocial forces. We will also reject any
              unreasonable demands by antisocial forces.
            </p>
          </li>
          <li>
            <p>
              We will take legal action, both civil and criminal, against
              unjustified demands by antisocial forces.
            </p>
          </li>
          <li>
            <p>
              We will never engage in backroom deals or provide funds to
              antisocial forces.
            </p>
          </li>
        </ol>
      </>
    )
}

export default AntiSocialPrinciple

import styled from 'styled-components'

type SectionDescriptionProps = {
  description: string
}

const SectionDescription = ({ description }: SectionDescriptionProps) => (
  <StyledSectionDescription>{description}</StyledSectionDescription>
)

export default SectionDescription

const StyledSectionDescription = styled.p`
  font-size: 1.3em;
`

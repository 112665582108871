import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import media from '../../common/utils/media'

const ScrollDown = () => {
  const [hasScrolled, setHasScrolled] = useState(false)

  const [y, setY] = useState(window.scrollY)

  const handleNavigation = useCallback(
    (e: any) => {
      const window = e.currentTarget
       if (y < window.scrollY) {
        // scrolling down
        setHasScrolled(true)
      }
      setY(window.scrollY)
    },
    [y]
  )

  useEffect(() => {
    setY(window.scrollY)
    window.addEventListener('scroll', handleNavigation)

    return () => {
      window.removeEventListener('scroll', handleNavigation)
    }
  }, [handleNavigation])

  return (
    <StyledScrollDown className={hasScrolled ? 'hidden' : ''}>
      <div className="chevron"></div>
      <div className="chevron"></div>
      <div className="chevron"></div>
      <span className="text">Scroll down</span>
    </StyledScrollDown>
  )
}

export default ScrollDown

const StyledScrollDown = styled.div`
  & {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    bottom: 0;
    animation: fadeIn 2s backwards;
    animation-delay: 2s;

    &.hidden {
      animation: fadeOut 1s forwards;
    }
  }

  .chevron {
    position: absolute;
    width: 28px;
    height: 8px;
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
    animation: move 3s ease-out infinite;

    &:first-child {
        animation: move 3s ease-out 1s infinite;
    }

    &:nth-child(2) {
        animation: move 3s ease-out 2s infinite;
    }

    &:before,
    &:after {
        content: ' ';
        position: absolute;
        top: 0;
        height: 100%;
        width: 51%;
        background: #fff;
    }

    &:before {
        left: 0;
        transform: skew(0deg, 30deg);
    }

    &:after {
        right: 0;
        width: 50%;
        transform: skew(0deg, -30deg);
    }
  }

  span.text {
    display: block;
    margin-top: 6em;
    margin-left: -30px;
    font-family: 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    white-space: nowrap;
    opacity: 0.25;
    animation: pulse 2s linear alternate infinite;

    ${media.sp`
        margin-top: 4em;
    `}
  }
`

import styled from 'styled-components'
import ICO_LINKEDIN from '../../assets/images/icon-linkedin.png'
import ICO_MEDIUM from '../../assets/images/icon-medium.png'
import {
  COMPANY_ADDRESS,
  COMPANY_EMAIL,
  COMPANY_PHONE,
  URL_LINKEDIN,
  URL_MEDIUM,
} from '../../common/utils/constants'

const ContactSection = () => (
  <StyledContactSection className="contact-section">
    <h3>Contact Us</h3>
    <p>Address</p>
    <p>{COMPANY_ADDRESS}</p>
    <p>Phone</p>
    <p>{COMPANY_PHONE}</p>
    <p>Email</p>
    <p>{COMPANY_EMAIL}</p>
    <div className="external-links">
      <a href={URL_LINKEDIN} target="_blank" rel="noreferrer">
        <img className="img-linkedin" src={ICO_LINKEDIN} alt="LinkedIn" />
      </a>
      <a href={URL_MEDIUM} target="_blank" rel="noreferrer">
        <img className="img-medium" src={ICO_MEDIUM} alt="Medium" />
      </a>
    </div>
  </StyledContactSection>
)

export default ContactSection

const StyledContactSection = styled.div`
  p {
    color: #8a979e;
    margin: 10px 0;
  }

  img.img-linkedin {
    width: 35px;
    height: 29.75px;
  }

  img.img-medium {
    width: 31.5px;
    height: 31.5px;
    margin-left: 10px;
  }

  div.external-links {
    margin-top: 23px;
  }
`

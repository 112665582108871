import styled from 'styled-components'
import { ROUTE_HIRING, ROUTE_JOB_WITH_LANG } from '../../common/utils/constants'
import media from '../../common/utils/media'
import { useParams } from 'react-router-dom'
import { Fragment, useEffect, useState } from 'react'
import LanguageToggle from '../molecules/LanguageToggle'
import Header from '../organisms/Header'
import BackButton from '../atoms/BackButton'
import ApplyButton from '../atoms/ApplyButton'

const JobPostingPage = () => {
  const params = useParams() as any
  const parse = require('html-react-parser')

  const [job] = useState(params.job || '')
  const [selectedLang, setSelectedLang] = useState(params.lang || '')
  const [jobDescription, setJobDescription] = useState(null) as any
  const [corporateCulture, setCorporateCulture] = useState(null) as any
  const [langs, setLangs] = useState(null) as any

  const parseHTML = (htmlString: string) => {
    try {
      return parse(htmlString)
    } catch (err) {
      return ''
    }
  }

  const getData = () => {
    // prevent browser from caching the file by appending random parameter.
    // source: https://stackoverflow.com/a/15041641
    fetch(`../../jobs/job_descriptions/${job}.json?nocache=${(new Date()).getTime()}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        const { company_division, ...job_description_txt } = json
        const allLangs = Object.keys(job_description_txt)
        let tmpSelectedLang = selectedLang
        if (!tmpSelectedLang || !allLangs.includes(tmpSelectedLang)) {
          tmpSelectedLang = allLangs[0]
          setSelectedLang(tmpSelectedLang)
        }
        setLangs(allLangs)
        setJobDescription(json[tmpSelectedLang])

        // prevent browser from caching the file by appending random parameter.
        // source: https://stackoverflow.com/a/15041641
        fetch(`../../jobs/corporate_culture/${company_division}.json?nocache=${(new Date()).getTime()}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
          .then((response) => {
            return response.json()
          })
          .then((json) => {
            setCorporateCulture(json[tmpSelectedLang])
          })
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getData()
    // eslint-disable-next-line
  }, [])

  return jobDescription ? (
    <>
      <Header />
      <StyledJobPosting>
        <BackButton previousPage={ROUTE_HIRING} />
        <LanguageToggle
          routeWithLang={ROUTE_JOB_WITH_LANG.replace(':job', job || '')}
          langs={langs}
          selectedLang={selectedLang}
        />
        <h2>{jobDescription['title']}</h2>
        <br />
        <h3>{parseHTML(jobDescription['attraction_header'])}</h3>
        {jobDescription['attraction'] instanceof Array ? (
          <ul>
            {jobDescription['attraction'].map((attraction: string) => (
              <li key={attraction}>{attraction}</li>
            ))}
          </ul>
        ) : (
          <p>{parseHTML(jobDescription['attraction'])}</p>
        )}
        <h3>{parseHTML(jobDescription['main_description_header'])}</h3>
        <p>{parseHTML(jobDescription['main_description'])}</p>
        <h4>{parseHTML(jobDescription['responsibility_statement'])}</h4>
        <div className="responsibilities-container">
          {jobDescription['responsibilities'].map((responsibility: any) => {
            if (typeof responsibility === 'string') {
              return <li key={responsibility}>{parseHTML(responsibility)}</li>
            } else if (responsibility instanceof Object) {
              return (
                <div key={responsibility.category}>
                  <li>
                    <span>{responsibility.category}</span>
                    <ul className="nested-list">
                      {responsibility.list.map((item: string) => (
                        <li key={item}>{parseHTML(item)}</li>
                      ))}
                    </ul>
                  </li>
                </div>
              )
            }
            return <></>
          })}
        </div>
        <p>{parseHTML(jobDescription['sub_description'])}</p>
        {jobDescription['attractions_rewards'] && (
          <div>
            <h4>{parseHTML(jobDescription['attractions_rewards']['title'])}</h4>
            <p>{parseHTML(jobDescription['attractions_rewards']['text'])}</p>
          </div>
        )}
        <h3>{parseHTML(jobDescription['qualifications_header'])}</h3>
        {jobDescription['qualifications'].map((qual: any) => {
          return (
            <Fragment key={qual.category}>
              <p><strong>{parseHTML(qual.category)}</strong></p>
              <ul>
                {qual.list.map((item: string) => (
                  <li key={item}>{parseHTML(item)}</li>
                ))}
              </ul>
            </Fragment>
          )
        })}
        {jobDescription['career_path'] && (
          <div>
            <h3>{parseHTML(jobDescription['career_path']['title'])}</h3>
            <ul>
            {jobDescription['career_path']['list'].map((item: string)=><li key={item}>{parseHTML(item)}</li>)
            }</ul>
          </div>
        )}
        {corporateCulture && (
          <div>
            <h3>{parseHTML(corporateCulture['corporate_culture_header'])}</h3>
            {corporateCulture['corporate_culture']?.map(
              (corporateCultureObj: any) => (
                <Fragment key={corporateCultureObj.title}>
                  <h4>{corporateCultureObj.title}</h4>
                  {corporateCultureObj.content instanceof Array ? (
                    <ul>
                      {corporateCultureObj.content.map((point: string) => (
                        <li key={point}>{parseHTML(point)}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>{parseHTML(corporateCultureObj.content)}</p>
                  )}
                </Fragment>
              )
            )}
          </div>
        )}
        <br/>
        <ApplyButton lang={selectedLang} />
      </StyledJobPosting>
    </>
  ) : (
    <></>
  )
}

export default JobPostingPage

export const StyledJobPosting = styled.div`
  padding: 80px 100px;
  text-align: left;
  line-break: strict;
  width: 100%;

  ${media.tablet`
    padding: 50px;
    width: auto;
  `}

  h2 {
    margin-top: 0;
  }
  h2,
  h3 {
    width: 100%;
    text-align: left;
    margin-bottom: 0;
  }

  h3 {
    margin-top: 50px;
  }

  p {
    width: 50%;
    text-align: left;
    white-space: break-spaces;

    ${media.tablet`
      width: 100%;
    `}
  }

  div.responsibilities-container {
    width: 50%;
    ${media.tablet`
      width: 100%;
    `}
  }

  ul:not(.nested-list) {
    padding-inline-start: 15px;
    max-width: 50%;

    ${media.tablet`
      max-width: 100%;
    `}
  }

  ul {
    margin-top: 10px;
  }
  li span {
    font-weight: bold;
  }

  ul ul li {
    margin-left: -20px;
  }

  div.jobs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    grid-gap: 1rem;
    margin-top: 100px;

    div {
      border: 1px solid white;
      border-radius: 20px;
      padding: 100px 30px;
      font-size: 1.2em;
      font-weight: bold;
      color: white;
      cursor: pointer;
      position: relative;
    }

    div:before,
    div:after {
      border-right: 2.5px solid lightgrey;
      content: '';
      display: block;
      height: 10px;
      margin-top: -7px;
      position: absolute;
      transform: rotate(135deg);
      right: 20px;
      top: 50%;
      width: 0;
    }

    div:after {
      margin-top: -1px;
      transform: rotate(45deg);
    }
  }

  p a {
    color: white;
    cursor: pointer;
    margin-bottom: 10px;
    text-decoration: underline;

    :hover {
      color: lightgrey;
    }
  }
`

import {
  ROUTE_ANTI_SOCIAL_PRINCIPLE_WITH_LANG,
  ROUTE_PRIVACY_POLICY_WITH_LANG,
  ROUTE_SITE_POLICY_WITH_LANG,
} from '../../common/utils/constants'
import styled from 'styled-components'
import media from '../../common/utils/media'

const PolicyLinks = () => {
  return (
    <StyledPolicyLinks>
      <h3>Policies</h3>
      <a href={ROUTE_ANTI_SOCIAL_PRINCIPLE_WITH_LANG.replace(':lang', 'en')}>
        Anti-Social Forces Basic Principle
      </a>
      <a href={ROUTE_PRIVACY_POLICY_WITH_LANG.replace(':lang', 'en')}>
        Privacy Policy
      </a>
      <a href={ROUTE_SITE_POLICY_WITH_LANG.replace(':lang', 'ja')}>
        Site Policy
      </a>
    </StyledPolicyLinks>
  )
}

export default PolicyLinks

const StyledPolicyLinks = styled.div`
  display: grid;
  grid-template-columns: repeat(1, auto);
  grid-auto-rows: auto;
  align-self: start;

  a {
    color: #8a979e;
    cursor: pointer;
    margin-bottom: 10px;
    text-decoration: none;

    :hover {
      color: lightgrey;
    }
  }

  ${media.tablet`
    margin-top: 30px;
  `}
`

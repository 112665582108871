import styled from 'styled-components'
import media from '../../common/utils/media'
import { GridItem as GridItemType } from '../../common/utils/types'
import GridItem from '../atoms/GridItem'

type GridItemContainerProps = {
  gridItems: GridItemType[]
}

const GridItemContainer = ({ gridItems }: GridItemContainerProps) => {
  return (
    <StyledGridItemContainer className="grid-container">
      {gridItems.map((gridItem) => {
        return (
          <GridItem
            key={gridItem.title}
            icon={gridItem.icon}
            title={gridItem.title}
            description={gridItem.description}
            contents={gridItem.contents}
          />
        )
      })}
    </StyledGridItemContainer>
  )
}

export default GridItemContainer

const StyledGridItemContainer = styled.div`
  & {
    display: grid;
    grid-gap: 20px 60px;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-evenly;
  }

  ${media.tablet`
        grid-template-columns: repeat(1, auto);
  `}
`

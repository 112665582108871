import React from 'react'

type SitePolicyProps = {
  lang: string
}

const SitePolicy = ({ lang }: SitePolicyProps) => {
  // English version is not out yet so we ignore the lang input and just display JA text
  // if (lang === 'ja')
  return (
    <>
      <h2>サイトポリシー</h2>
      <p>
        本サイト（ログイン後の画面を含む。以下、同じ。）は、当社のご案内などのほか、当社サービスの提供を目的として運営しています。本サイトをご利用いただく際には、必ず次の内容をお読みいただき、ご承諾のうえご利用ください。なお、本サイトポリシーは予告なしに内容の変更または廃止される場合がありますので、予めご了承ください。
      </p>
      <ol>
        <li className="bolded">
          著作権等について
          <p>
            本サイトに掲載されているすべての内容の著作権その他の権利は、原則として当社および原情報の提供者に帰属いたします。当該情報等は日本国著作権法および国際条約により保護されています。ご利用にあたっては、無断で転用、複製等を行うことはできません。私的使用その他法律により明示的に認められる範囲を超えて、これらの情報を当社の事前の承諾なしに、使用（複製、改変、アップロード、掲示、送信、領布、ライセンス、販売、出版等を含む）することを禁止します。
          </p>
          <p>
            また、お客様は、本サイトに掲載されている情報等については、全てお客様自身のためにのみご利用するものとし、次の目的ではご利用できないものとします。
          </p>
          <ul className="visible-list-item">
            <li>第三者に開示又は提供する目的</li>
            <li>
              情報又は内容（これらを複写したものを含みます。）を第三者に漏えいし、または第三者との間で共同利用する目的
            </li>
            <li>情報を加工または再利用（再配信を含みます。）する目的</li>
            <li>営利目的により利用する目的</li>
          </ul>
        </li>
        <li className="bolded">
          他社サイトへのリンクについて
          <p>
            本サイトから、リンクやバナーによって他の第三者が運営するWebサイトへ遷移することがありますが、第三者が運営するWebサイトの情報等について、当社はその内容の正確性、信頼性についてなんら保証するものではありません。
          </p>
          <p>
            また、リンク先のWebサイトは当社が運営するものではないため、連絡なくアドレスが変更もしくは移動する恐れがありますのでご注意ください。また、当社はリンク先である第三者が運営するWebサイトをご利用になったことにより生じたいかなる損害に関して、当社はいかなる責任も負いかねます。
          </p>
          <p>
            なお、本サイトへのリンクを貼る場合は、当社の承諾を必要といたします。
          </p>
        </li>
        <li className="bolded">
          免責について
          <p>
            本サイトに掲載する投資情報は、情報提供を目的としたものであり、金融商品の投資等の勧誘を意図したものではありません。本サイトの内容は当社が信頼できると判断する情報から作成されておりますが、正確性、適時性等を保証するものではありません。本サイトの内容に依拠したことよって生じた損害について、当社はなんらの責任を負うものではありません。投資の判断・決定については、お客様ご自身の責任により行っていただきますようお願いいたします。
          </p>
        </li>
        <li className="bolded">
          準拠法について
          <p>
          本サイトの利用に関しては日本国法に準拠します。また、本サイトの掲載事項に係る紛争解決については、当社本店の所在地を管轄とする東京地方裁判所を管轄裁判所といたします。
          </p>
        </li>
        <li className="bolded">
          Cookie（クッキー）について
          <p>
            Cookieとは、Webブラウザを通してお客様のコンピュータのハードディスクに送信される英数字により構成されたごく小さなデータのことをいいます。本サイトをご利用いただいたお客様の閲覧状況の全体的な傾向を把握し、セキュリティの確保やお客様への適切な情報提供を目的として使用するための情報であり、お客様を特定するものではありません。Cookieによって、お客様のお名前やEmailアドレス、住所、電話番号などを当社が収集することはありません。
          </p>
        </li>
        <li className="bolded">
          Google Analytics の使用
          <p>
            本サイトでは、アクセス情報の収集、解析およびリマーケティング広告配信のためにGoogle
            Analyticsを使用しています。Google
            Analyticsでは、上記5にてご説明したCookieを使用し、お客様個人を特定する情報を含まずにアクセス情報の収集を行います。
          </p>
          <p>
            収集されたアクセス情報は、Google社のプライバシーポリシーに基づいて管理されます。
          </p>
          <p>
            各種の配信事業を行うGoogle社を含む第三者は、インターネット上の様々なWEBサイトにおいて当社の広告を掲載する際、お客様の当社WEBサイトへのアクセス情報を使用することがあります。
          </p>
          <p>
            アクセス情報等に基づいた広告配信を希望しない場合、Google社によるGoogle
            Analyticsオプトアウトアドオンをダウンロードすることによりアクセス情報に基づいた広告配信を停止することができます。また、Network
            Advertising
            Initiativeのオプトアウトページからも配信を解除することもできます。
          </p>
          <p>
            <ul className="visible-list-item">
              <li>
                <a
                  href="https://www.google.com/intl/ja/policies/privacy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Google 社のプライバシーポリシー
                </a>
              </li>
              <li>
                <a
                  href="https://tools.google.com/dlpage/gaoptout?hl=ja"
                  target="_blank"
                  rel="noreferrer"
                >
                  Google Analytics オプトアウトアドオン
                </a>
              </li>
              <li>
                <a
                  href="https://optout.networkadvertising.org/#/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Network Advertising Initiative のオプトアウトページ
                </a>
              </li>
            </ul>
          </p>
        </li>
        <li className="bolded">
          本サイトご利用環境について
          <p>
            本サイトをご利用いただくにあたっての推奨環境は以下のとおりです。
          </p>
          <ul>
            <li>Windows：Google Chrome 最新版、Microsoft Edge 最新版</li>
            <li>macOS：Google Chrome 最新版、Safari 最新版</li>
            <li>iOS 最新版：Safari 最新版</li>
            <li>Android 12.0以上：Google Chrome 最新版</li>
          </ul>
          <p>注意事項</p>
          <ul className="visible-list-item">
            <li>
              推奨環境を満たしている場合においても、お使いの機器や環境により、一部の機能が正常に動作しない場合があります。
            </li>
            <li>
              企業などの環境からインターネット接続をご利用の場合、ファイアウォールの設定等によって、本サイトをご覧いただけない場合があります。詳しくは、お客様側のネットワーク管理者におたずねください。
            </li>
          </ul>
        </li>
      </ol>
    </>
  )
}

export default SitePolicy

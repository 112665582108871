import { useHistory } from 'react-router-dom'
import ICO_BACK from '../../assets/images/icon-left-arrow.png'
import { ROUTE_ROOT } from '../../common/utils/constants'
import styled from 'styled-components'

type BackButtonProps = {
  previousPage?: string
}

const BackButton = ({previousPage}: BackButtonProps) => {
  const history = useHistory()

  const back = () => {
    history.push(previousPage || ROUTE_ROOT)
  }

  return (
    <StyledBackButton
      className="-pointer icon"
      src={ICO_BACK}
      alt={'Go back'}
      title={'Go back'}
      onClick={back}
    />
  )
}

export default BackButton

const StyledBackButton = styled.img`
  & {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
`
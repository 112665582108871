import styled from 'styled-components'
import media from '../../common/utils/media'
import { ROUTE_ABOUT_US, ROUTE_HIRING } from '../../common/utils/constants'

const Header = () => (
  <StyledHeader>
    <div className="top-bar">
      <a href="/" className="title">
        AlpacaTech
      </a>
      <a
        href={ROUTE_ABOUT_US}
        className={
          window.location.pathname.includes(ROUTE_ABOUT_US) ? 'active' : ''
        }
      >
        About Us
      </a>
      <a
        href={ROUTE_HIRING}
        className={
          window.location.pathname.includes(ROUTE_HIRING) ? 'active' : ''
        }
      >
        Jobs
      </a>
    </div>
  </StyledHeader>
)

export default Header

const StyledHeader = styled.header`
  & {
    padding: 3vh 100px;

    ${media.tablet`
      padding: 3vh 50px;
    `}

    div.top-bar {
      display: flex;
      align-items: center;

      a {
        white-space: pre;
      }

      a.title {
        text-decoration: none;
        color: #ff3333;
        font-size: 1.5em;
        font-weight: 800;
      }

      a:not(.title) {
        text-decoration: none;
        font-size: 1.1em;
        font-weight: 800;
        margin-left: 50px;
      }

      ${media.sp` 
        a.title {
          font-size: 1.1em;
        }
        a:not(.title) {
          font-size: 1em;
          margin-left: 30px;
        }
      `}

      a:not(.title):hover {
        color: #e3e3e3;
      }
    }
  }
`

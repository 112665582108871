import { Fragment } from 'react'
import styled from 'styled-components'

type LanguageToggleProps = {
  routeWithLang: string
  langs: string[]
  selectedLang: string
}

const LanguageToggle = ({
  routeWithLang,
  langs,
  selectedLang,
}: LanguageToggleProps) => (
  <StyledLanguageToggle>
    {langs.map((lang: string, idx: number) => (
      <Fragment key={lang}>
        <a
          href={routeWithLang.replace(':lang', lang)}
          className={lang.toUpperCase() === selectedLang.toUpperCase() ? 'selected' : ''}
        >
          {lang.toUpperCase()}
        </a>
        {idx === langs.length - 1 ? '' : ' | '}
      </Fragment>
    ))}
  </StyledLanguageToggle>
)

export default LanguageToggle

const StyledLanguageToggle = styled.div`
  margin-top: 40px;
  margin-left: 4px;
  font-size: 0.9em;
  color: lightgray;

  a:not(.selected) {
    color: lightgray;
  }

  a.selected {
    font-weight: bold;
  }

  a:hover {
    color: white;
`

import {
  ROUTE_ABOUT_US_WITH_LANG,
  ROUTE_SITE_POLICY_WITH_LANG,
} from '../../common/utils/constants'

type PrivacyPolicyProps = {
  lang: string
}

const PrivacyPolicy = ({ lang }: PrivacyPolicyProps) => {
  // TODO: uncomment if else condition when there is eng text for privacy policy
  // if (lang === 'ja')
  return (
    <>
      <h2>個人情報保護方針</h2>
      <p>
        当社は、個人情報の重要性を認識し、個人情報を保護することが社会的責務であると考え、個人情報に関する法令および社内規程等を遵守し、当社で取扱う個人情報の取得、利用、管理を適正に行います。
      </p>
      <p>
        お客様の個人情報および個人番号（以下、<b>「個人情報等」</b>
        といいます。）の適切な保護と利用に関する取組み方針として、次の個人情報保護方針を策定し、公表いたします。
      </p>
      <ol>
        <li className="bolded">
          関係法令等の遵守
          <p>
            当社は、個人情報等の保護に関する関係諸法令、個人情報保護委員会によるガイドライン等ならびにこの個人情報保護方針を遵守いたします。
          </p>
        </li>
        <li className="bolded">
          個人情報の取得と利用目的
          <p>
            当社は、お客様が当社のサービス利用する際に、個人情報を取得することがあります。
          </p>
          <p>
            お客様の同意を得た場合および法令等により例外として取り扱われる場合を除き、以下に定める利用目的の達成に必要な範囲内で、お客様の個人情報等を取り扱います。
          </p>
          <p>
            なお、個人番号については、法令等で定められた範囲内でのみ取り扱います。
          </p>
        </li>
        <li className="bolded">
          個人情報の利用目的
          <p>当社が個人情報を収集・利用する目的は以下のとおりです。</p>
          <ol>
            <li>当社サービスの勧誘、案内、提供または運営を行うため</li>
            <li>
              当社サービスの申込の受付またはサービス提供に関する事務を行うため
            </li>
            <li>
              お客様からのお問い合わせに回答するため（本人確認を行うことを含む）
            </li>
            <li>
              お客様が利用中のサービスの新機能、更新情報、キャンペーン等および当社が提供する他のサービスの案内のメールを送付するため
            </li>
            <li>メンテナンス、重要なお知らせなど必要に応じたご連絡のため</li>
            <li>
              利用規約に違反したお客様や、不正・不当な目的でサービスを利用しようとするお客様の特定をし、ご利用をお断りするため
            </li>
            <li>
              お客様にご自身の登録情報の閲覧や変更、削除、ご利用状況の閲覧を行っていただくため
            </li>
            <li>有料サービスにおいて、お客様に利用料金を請求するため</li>
            <li>
              市場調査、データ分析またはアンケートの実施等により当社商品またはサービスの研究または開発を行うため
            </li>
            <li>
              当社または提携会社の業務に関する広告、宣伝、調査、情報の分析または情報の提供に関する業務を遂行するため
            </li>
            <li>
              他の事業者等から委託された個人情報の処理を含む業務を適切に遂行するため
            </li>
            <li>お客様との契約や法律等に基づく権利の行使や義務の履行のため</li>
            <li>上記の利用目的に付随する目的</li>
          </ol>
        </li>
        <li className="bolded">
          個人情報の管理
          <p>
            当社は、お客様の個人情報等を正確かつ最新の内容とするよう努めるとともに、お客様の個人情報等の紛失、改ざんおよび漏洩等を防止するために、基本方針の策定、個人データの取扱いに係る規律の整備ならびに必要かつ適切な組織的、人的、物理的および技術的な安全管理措置を実施いたします。その他、お客さまの個人情報等を取り扱う役員、従業員や委託先（再委託先等も含みます。以下、同じ。）について、必要かつ適切な監督を行います。
          </p>
        </li>
        <li className="bolded">
          第三者提供の有無
          <p>
            当社は、法令・諸規則または当社との契約に基づく場合を除き、個人情報等をお客様の承諾なしに当社以外の第三者（当社との間で秘密保持契約を締結している業務委託先を除く）に提供することはありません。
          </p>
          <p>
            当社は、法令等に許される場合を除き、あらかじめ外国にある第三者への提供を認める旨のお客様の同意を得なければ、個人情報を外国にある第三者に提供することはいたしません。
          </p>
          <p>
            なお、個人番号については、法令等に許される場合を除き、お客様の承諾があっても当社以外の第三者（当社との間で秘密保持契約を締結している業務委託先を除く）に提供することはありません。
          </p>
        </li>
        <li className="bolded">
          Cookie等の利用について
          <p>
            当社におけるCookie（クッキー）等の利用については、
            <a
              href={ROUTE_SITE_POLICY_WITH_LANG.replace(':lang', 'ja')}
              target="_blank"
              rel="noreferrer"
            >
              サイトポリシー
            </a>
            をご確認ください。
          </p>
        </li>
        <li className="bolded">
          問い合せ先
          <p>
            個人情報の取扱いに関するご質問・苦情等または開示等のご請求は、下記の窓口まで電話にてお問い合わせください。
          </p>
          <text>【受付窓口】</text>
          <ul>
            <li>AlpacaTech株式会社　総務部</li>
            <li>住所 ：〒101-0047　東京都千代田区内神田1-13-14</li>
            <li>電話 ：03-6632-8865</li>
            <li>受付時間　平日10:00-17:00 （土日祝祭日は休業となります）</li>
          </ul>
        </li>
        <li className="bolded">
          会社概要
          <p>
            AlpacaTech株式会社の本社所在地、役員、その他の会社概要は、ホームページの
            <a
              href={ROUTE_ABOUT_US_WITH_LANG.replace(':lang', 'ja')}
              target="_blank"
              rel="noreferrer"
            >
              Corporate Info
            </a>
            をご覧ください。
          </p>
        </li>
      </ol>
      <br />
      <p>以上</p>
    </>
  )
  // else
  //   return (
  //     // English version
  //     <>
  //       <h2>Privacy Policy</h2>
  //       <p>
  //         We recognize the importance of personal information and believe that
  //         it is our social responsibility to protect personal information. We
  //         will comply with laws, regulations, and internal rules regarding
  //         personal information, and will properly acquire, use, and manage
  //         personal information handled by us.
  //       </p>
  //       <p>
  //         For the appropriate protection and use of customers' personal
  //         information and personal identity numbers (hereinafter referred to as
  //         "personal information, etc."), the following Privacy Policy has been
  //         established and published.
  //       </p>
  //       <ol>
  //         <li className="bolded">
  //           Personal Information
  //           <p>
  //             "Personal Information" refers to "Personal Information" as defined
  //             in the Act on the Protection of Personal Information, and refers
  //             to information about a living individual that can identify the
  //             specific individual by name, date of birth, address, telephone
  //             number, contact information, or other description contained in the
  //             information, as well as information that can identify the specific
  //             individual from the information itself (personal identification
  //             information) such as appearance, fingerprint, voice print data,
  //             and health insurance card holder number.
  //           </p>
  //         </li>
  //         <li className="bolded">
  //           Purpose of obtaining and using Personal Information
  //           <p>
  //             We may collect personal information when you use our services.
  //           </p>
  //           <p>
  //             With the exception of cases in which our company has obtained the
  //             consent of the customer or is treated as an exception by law, our
  //             company will handle the customer's personal information, etc.
  //             within the scope necessary to achieve the purposes of use as set
  //             forth below.
  //           </p>
  //         </li>
  //         <li className="bolded">
  //           Purpose of use of Personal Information
  //           <p>
  //             The purposes for which we collect and use personal information are
  //             as follows.
  //           </p>
  //           <ol>
  //             <li>To provide and operate our services</li>
  //             <li>
  //               To respond to customer inquiries (includes verifying the
  //               identity of the customer)
  //             </li>
  //             <li>
  //               To send to our customer, information about new features,
  //               updates, campaigns, etc. of the service the customer is using,
  //               as well as information about other services provided by us.
  //             </li>
  //             <li>
  //               To contact our customer as necessary for maintenance, important
  //               notices, etc.
  //             </li>
  //             <li>
  //               To identify customers who violate the Terms of Use or who
  //               attempt to use the service for fraudulent or unfair purposes,
  //               and to refuse their use of the service.
  //             </li>
  //             <li>
  //               To allow customers to view, change, or delete their own
  //               registration information, or view the status of their usage
  //             </li>
  //             <li>To charge customers for paid services</li>
  //             <li>Purposes incidental to the above purposes of use</li>
  //           </ol>
  //         </li>
  //         <li className="bolded">
  //           Management of Personal Information
  //           <p>
  //             We will endeavor to keep customers' personal information, etc.
  //             accurate and up-to-date, and implement necessary and appropriate
  //             security control measures to prevent the loss, falsification,
  //             leakage, etc. of customers' personal information, etc. We will
  //             also supervise the officers, employees, and contractors (including
  //             subcontractors, etc.) handling customers' personal information,
  //             etc. in a necessary and appropriate manner.
  //           </p>
  //         </li>
  //         <li className="bolded">
  //           Availability of Third-party Information
  //           <p>
  //             We will not provide personal information, etc. to any third party
  //             other than us (excluding subcontractors with whom we have entered
  //             into confidentiality agreements) without the consent of the
  //             customer, except as required by law, regulation, or contract with
  //             us.
  //           </p>
  //           <p>
  //             Personal identity numbers will not be provided to any third party
  //             (except for subcontractors with whom we have entered into
  //             confidentiality agreements), except as permitted by law, even with
  //             the consent of the customer.
  //           </p>
  //         </li>
  //         <li className="bolded">
  //           Inquiries
  //           <p>
  //             Please call the following contact point for information regarding
  //             the handling of personal information.
  //           </p>
  //           <text>【Contact】</text>
  //           <ul>
  //             <li>AlpacaTech Co., Ltd. / General Affairs Department</li>
  //             <li>Address: 101-0047 1-13-14 Uchikanda, Chiyoda-ku Tokyo</li>
  //             <li> Tel: 03-6632-8865</li>
  //             <li>
  //               Office Hours: Weekdays 10:00-17:00 (Closed on Saturdays, Sundays
  //               and national holidays)
  //             </li>
  //           </ul>
  //         </li>
  //       </ol>
  //     </>
  //   )
}

export default PrivacyPolicy

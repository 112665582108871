import { css } from 'styled-components'

const media = {
  pcNormal: (...args: any) => css`
    @media only screen and (min-width: 1281px) {
      ${css(args)};
    }
  `,
  tablet: (...args: any) => css`
    @media only screen and (max-width: 1280px) {
      ${css(args)};
    }
  `,
  pcAndTablet: (...args: any) => css`
    @media only screen and (min-width: 641px) {
      ${css(args)};
    }
  `,
  sp: (...args: any) => css`
    @media (max-width: 640px) {
      ${css(args)};
    }
  `,
  spSmall: (...args: any) => css`
    @media (max-width: 480px) {
      ${css(args)};
    }
  `,
  spExSmall: (...args: any) => css`
    @media (max-width: 320px) {
      ${css(args)};
    }
  `,
  hackIe11: (...args: any) => css`
    @media all and (-ms-high-contrast: none) {
      *::-ms-backdrop,
      & {
        ${css(args)};
      }
    }
  `,
  chromeSp: (...args: any) => css`
    @media (max-width: 640px) and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
      ${css(args)};
    }
  `,
}
export default media
